var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-form",
        { attrs: { readonly: !_vm.canEditSettings } },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Drivers")]),
              _c(
                "v-card-text",
                [
                  _c("h2", { staticClass: "mb-3" }, [_vm._v("Driver Table")]),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label: "Secondary Location Label",
                            },
                            model: {
                              value: _vm.config.secondaryLocLabel,
                              callback: function ($$v) {
                                _vm.$set(_vm.config, "secondaryLocLabel", $$v)
                              },
                              expression: "config.secondaryLocLabel",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label: "Label for Routing Driver Hours",
                            },
                            model: {
                              value: _vm.config.routingDriverHoursLabel,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.config,
                                  "routingDriverHoursLabel",
                                  $$v
                                )
                              },
                              expression: "config.routingDriverHoursLabel",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label: "Preference Label 1",
                            },
                            model: {
                              value: _vm.config.prefLabel1,
                              callback: function ($$v) {
                                _vm.$set(_vm.config, "prefLabel1", $$v)
                              },
                              expression: "config.prefLabel1",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label: "Preference Label 2",
                            },
                            model: {
                              value: _vm.config.prefLabel2,
                              callback: function ($$v) {
                                _vm.$set(_vm.config, "prefLabel2", $$v)
                              },
                              expression: "config.prefLabel2",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label: "Preference Label 3",
                            },
                            model: {
                              value: _vm.config.prefLabel3,
                              callback: function ($$v) {
                                _vm.$set(_vm.config, "prefLabel3", $$v)
                              },
                              expression: "config.prefLabel3",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label: "Preference Label 4",
                            },
                            model: {
                              value: _vm.config.prefLabel4,
                              callback: function ($$v) {
                                _vm.$set(_vm.config, "prefLabel4", $$v)
                              },
                              expression: "config.prefLabel4",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label: "Preference Label 5",
                            },
                            model: {
                              value: _vm.config.prefLabel5,
                              callback: function ($$v) {
                                _vm.$set(_vm.config, "prefLabel5", $$v)
                              },
                              expression: "config.prefLabel5",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label: "Preference Label 6",
                            },
                            model: {
                              value: _vm.config.prefLabel6,
                              callback: function ($$v) {
                                _vm.$set(_vm.config, "prefLabel6", $$v)
                              },
                              expression: "config.prefLabel6",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("h2", { staticClass: "mb-3" }, [_vm._v("Assignments")]),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label: "Label for Driver Assistant",
                            },
                            model: {
                              value: _vm.config.driverAssistantLabel,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.config,
                                  "driverAssistantLabel",
                                  $$v
                                )
                              },
                              expression: "config.driverAssistantLabel",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("h2", { staticClass: "mb-3" }, [
                    _vm._v("Find available drivers"),
                  ]),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-subheader", { staticClass: "pl-0" }, [
                            _vm._v(
                              " Do you want to schedule Drivers based on Driver Availability? "
                            ),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "" },
                              model: {
                                value: _vm.config.useAvailableDrivers,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.config,
                                    "useAvailableDrivers",
                                    $$v
                                  )
                                },
                                expression: "config.useAvailableDrivers",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { label: "Yes", value: true },
                              }),
                              _c("v-radio", {
                                attrs: { label: "No", value: false },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "bg-blue", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-subheader", { staticClass: "pl-0" }, [
                            _vm._v(
                              " Allow Driver to ACCEPT driver assignment* "
                            ),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: {
                                row: "",
                                disabled: !_vm.config.useAvailableDrivers,
                              },
                              model: {
                                value: _vm.config.driverAccept,
                                callback: function ($$v) {
                                  _vm.$set(_vm.config, "driverAccept", $$v)
                                },
                                expression: "config.driverAccept",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { value: true },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [_vm._v(" Yes ")]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                              _c("v-radio", {
                                attrs: { value: false },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [_vm._v(" No ")]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-subheader", { staticClass: "pl-0" }, [
                            _vm._v(
                              " Allow Driver to DECLINE driver assignment* "
                            ),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: {
                                row: "",
                                disabled: !_vm.config.useAvailableDrivers,
                              },
                              model: {
                                value: _vm.config.driverDecline,
                                callback: function ($$v) {
                                  _vm.$set(_vm.config, "driverDecline", $$v)
                                },
                                expression: "config.driverDecline",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { value: true },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [_vm._v(" Yes ")]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                              _c("v-radio", {
                                attrs: { value: false },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [_vm._v(" No ")]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-subheader", { staticClass: "pl-0" }, [
                            _vm._v(
                              " *Selecting Yes for either of these options requires that all drivers be listed in the driver table and you will not be allowed to type in a name that is not in the table. "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-subheader", { staticClass: "pl-0" }, [
                            _vm._v(
                              "Default Designation to filter available drivers"
                            ),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "" },
                              model: {
                                value: _vm.config.defaultDesignation,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.config,
                                    "defaultDesignation",
                                    $$v
                                  )
                                },
                                expression: "config.defaultDesignation",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { value: "All", label: "All" },
                              }),
                              _c("v-radio", {
                                attrs: { value: "None", label: "None" },
                              }),
                              _vm._l(_vm.designations, function (des, i) {
                                return _c("v-radio", {
                                  key: i,
                                  attrs: { value: des, label: des },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "bg-blue", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-subheader", { staticClass: "pl-0" }, [
                            _vm._v("Default Zone for new drivers"),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "" },
                              model: {
                                value: _vm.config.defaultZone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.config, "defaultZone", $$v)
                                },
                                expression: "config.defaultZone",
                              },
                            },
                            _vm._l(_vm.zoneOptions, function (zone, i) {
                              return _c("v-radio", {
                                key: i,
                                attrs: { value: zone, label: zone.text },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-subheader", { staticClass: "pl-0" }, [
                            _vm._v(
                              "Location to be used to determine the zone of a trip:"
                            ),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "" },
                              model: {
                                value: _vm.config.determineZoneBy,
                                callback: function ($$v) {
                                  _vm.$set(_vm.config, "determineZoneBy", $$v)
                                },
                                expression: "config.determineZoneBy",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  label: "Request Location",
                                  value: "request",
                                },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  label: "Vehicle Location",
                                  value: "vehicle",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "bg-blue", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-subheader", { staticClass: "pl-0" }, [
                            _vm._v(
                              " Select weekly rotation to use the number of trips and hours from the trips already assigned to the drivers for the week of the trip. Select continuous rotation to use cumulative trips and hours that are accumulated on the driver table. For continuous rotation, number trips and hours may be adjusted to allow new drivers to fit in the rotation. "
                            ),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "" },
                              model: {
                                value: _vm.config.defaultSortRotation,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.config,
                                    "defaultSortRotation",
                                    $$v
                                  )
                                },
                                expression: "config.defaultSortRotation",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { label: "No Rotation", value: "none" },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  label: "Weekly Rotation",
                                  value: "weekly",
                                },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  label: "Continous Rotation",
                                  value: "continuous",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _vm.config.defaultSortRotation === "weekly"
                            ? _c("v-select", {
                                attrs: {
                                  items: [
                                    { value: 0, text: "Sunday" },
                                    { value: 1, text: "Monday" },
                                    { value: 2, text: "Tuesday" },
                                    { value: 3, text: "Wednesday" },
                                    { value: 4, text: "Thursday" },
                                    { value: 5, text: "Friday" },
                                    { value: 6, text: "Saturday" },
                                  ],
                                  label: "Starting Day",
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.config.weeklyRotationStart,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.config,
                                      "weeklyRotationStart",
                                      $$v
                                    )
                                  },
                                  expression: "config.weeklyRotationStart",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-subheader", { staticClass: "pl-0" }, [
                            _vm._v("Default sort option for the driver list:"),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "" },
                              model: {
                                value: _vm.config.defaultSortOption,
                                callback: function ($$v) {
                                  _vm.$set(_vm.config, "defaultSortOption", $$v)
                                },
                                expression: "config.defaultSortOption",
                              },
                            },
                            _vm._l(_vm.sortOptions, function (option, i) {
                              return _c("v-radio", {
                                key: i,
                                attrs: {
                                  label: option.text,
                                  value: option.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "bg-blue", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-subheader", { staticClass: "pl-0" }, [
                            _vm._v(
                              " Display Driver tooltip on Available Drivers? "
                            ),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "" },
                              model: {
                                value: _vm.config.driverTooltip,
                                callback: function ($$v) {
                                  _vm.$set(_vm.config, "driverTooltip", $$v)
                                },
                                expression: "config.driverTooltip",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { label: "Yes", value: true },
                              }),
                              _c("v-radio", {
                                attrs: { label: "No", value: false },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }